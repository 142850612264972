import React from "react";
import style from "./ChipScroll.module.scss";

const ChipScroll = () => {
  return (
    <div className={style.chipContainer}>
      <svg
        className={`${style.arrow}`}
        width="17"
        height="51"
        viewBox="0 0 17 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.24998 2L9.24998 48.5M9.24998 48.5L15.5 40.75M9.24998 48.5L1.99998 40.75"
          stroke="#17082F"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default ChipScroll;
