import CustomPlane from "@/components/common/3D/CustomPlane";
import { Physics, usePlane, useSphere } from "@react-three/cannon";
import { useTexture } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { gsap } from "gsap";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { InstancedMesh, MeshPhongMaterial, SphereGeometry } from "three";

import Scene from "@/components/common/3D/Scene";
import { useDeviceDetector } from "@/utils/useDeviceDetector";

const Plane = ({ color, ...props }) => {
  usePlane(() => ({ ...props }));
  return null;
};

const Mouse = ({ isMobile }) => {
  const { size, viewport } = useThree();
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [isMoving, setIsMoving] = useState(false);

  let timeoutID;
  let onAnimation = useRef(null);

  const api = useSphere(() => ({
    type: "Kinematic",
    args: [isMobile ? 3 : 6],
  }))[1];

  const api2 = useSphere(() => ({
    type: "Kinematic",
    args: [isMobile ? 3 : 6],
  }))[1];

  const api3 = useSphere(() => ({
    type: "Kinematic",
    args: [isMobile ? 3 : 6],
  }))[1];

  useLayoutEffect(() => {
    const handleMouseMove = (e) => {
      const x = (e.clientX / size.width) * 2 - 1;
      const y = -(e.clientY / size.height) * 2 + 1;
      setCoords({ x, y });
      setIsMoving(true);
      clearTimeout(timeoutID);
      timeoutID = setTimeout(() => setIsMoving(false), 100);
    };

    const v = { a: 1 };
    let tween;

    const handleClick = (e) => {
      onAnimation.current = true;

      const pointerX = (e.clientX / size.width) * 2 - 1;
      const pointerY = -(e.clientY / size.height) * 2 + 1;

      tween = gsap.to(v, {
        a: 10,
        duration: 0.5,
        ease: "linear",
        onUpdate: () => {
          const offsetX = (pointerX * viewport.width) / 2 + Math.sin(v.a) * 5;
          const offsetY = (pointerY * viewport.height) / 2;
          const offsetZ = Math.cos(v.a) * 10;

          [api, api2, api3].forEach((apiRef, index) =>
            apiRef.position.set(
              offsetX,
              offsetY,
              offsetZ * (index === 1 ? -1 : 1)
            )
          );
        },
        onComplete: () => {
          onAnimation.current = false;
        },
      });
    };

    document.body.addEventListener("mousemove", handleMouseMove);
    document.body.addEventListener("click", handleClick);

    return () => {
      document.body.removeEventListener("mousemove", handleMouseMove);
      document.body.removeEventListener("click", handleClick);
      tween?.kill();
      clearTimeout(timeoutID);
    };
  }, [api, api2, api3, viewport, size]);

  useFrame(() => {
    const offsetX = (coords.x * viewport.width) / 2;
    const offsetY = (coords.y * viewport.height) / 2;

    if (!onAnimation.current) {
      if (isMoving) {
        api.position.set(offsetX, offsetY, 0);
        api2.position.set(offsetX, offsetY, 5);
        api3.position.set(offsetX, offsetY, 10);
      } else {
        api.position.set(-50, -50, 0);
        api2.position.set(-50, -50, 0);
        api3.position.set(-50, -50, 0);
      }
    }
  });

  return null;
};

const Borders = ({ isMobile }) => {
  const { viewport } = useThree();
  const wallProps = [
    {
      position: [
        0,
        isMobile ? -viewport.height / 1.9 : -viewport.height / 2,
        5,
      ],
      rotation: [-Math.PI / 2, 0, 0],
    },
    {
      position: [
        isMobile ? -viewport.width * 1.15 : -viewport.width * 0.7,
        0,
        5,
      ],
      rotation: [0, Math.PI / 2, 0],
    },
    {
      position: [isMobile ? viewport.width * 1.15 : viewport.width * 0.7, 0, 5],
      rotation: [0, -Math.PI / 2, 0],
    },
    { position: [0, 0, -1], rotation: [0, 0, 0] },
    {
      position: [0, 0, isMobile ? 10 : 11],
      rotation: [0, -Math.PI, 0],
    },
  ];

  return (
    <>
      {wallProps.map((props, index) => (
        <Plane key={index} color={0x00ff00} {...props} />
      ))}
    </>
  );
};

const InstancedSpheres = ({
  count = 0,
  geoSize = 1,
  randomScale = 1,
  isMobile = false,
}) => {
  const { viewport } = useThree();
  const texture = useTexture("/balltexture.jpg");
  const matcap = useTexture("/matcap.png");

  const [ref] = useSphere(() => ({
    mass: 5,
    position: [Math.random() * 8, viewport.height, 5 * Math.random()],
    args: [geoSize],
    type: "Dynamic",
  }));

  const instancedMeshRef = ref as React.MutableRefObject<
    InstancedMesh<SphereGeometry, MeshPhongMaterial>
  >;

  useEffect(() => {
    return () => {
      if (instancedMeshRef.current) {
        instancedMeshRef.current.geometry.dispose();
        if (Array.isArray(instancedMeshRef.current.material)) {
          instancedMeshRef.current.material.forEach((material) =>
            material.dispose()
          );
        } else {
          instancedMeshRef.current.material.dispose();
        }
      }
    };
  }, [instancedMeshRef]);

  return (
    <instancedMesh
      ref={instancedMeshRef}
      args={[null, null, count]}
      scale={1 * randomScale}
    >
      <sphereGeometry
        args={[geoSize, isMobile ? 24 : 28, isMobile ? 24 : 28]}
      />
      <meshMatcapMaterial map={texture} matcap={matcap} />
    </instancedMesh>
  );
};

const SceneContent = ({ gravity, isMobile }) => {
  const sphereConfigs = [
    { count: isMobile ? 30 : 40, geoSize: isMobile ? 1.25 : 1.5 },
    { count: isMobile ? 25 : 50, geoSize: isMobile ? 1.5 : 2 },
    { count: isMobile ? 30 : 40, geoSize: 1 },
  ];

  return (
    <Physics
      gravity={gravity}
      defaultContactMaterial={{ restitution: 0.68, friction: 0.5 }}
    >
      <group position={[0, 0, -10]}>
        {sphereConfigs.map((config, index) => (
          <InstancedSpheres key={index} isMobile={isMobile} {...config} />
        ))}
        <Mouse isMobile={isMobile} />
        <Borders isMobile={isMobile} />
      </group>
    </Physics>
  );
};

export const Ballpit = () => {
  const [gravity, setGravity] = useState<any>([0, 0, 0]);
  const scrollContainerRef = useRef(null);
  const { isMobile } = useDeviceDetector();

  useLayoutEffect(() => {
    if (!scrollContainerRef.current) return;
    const tween = gsap.to(scrollContainerRef.current, {
      scrollTrigger: {
        trigger: scrollContainerRef.current,
        start: "top center",
        scrub: 1,
        onEnter: () => {
          setGravity([0, -30, 0]);
        },
      },
    });

    return () => {
      tween.scrollTrigger?.kill();
      tween.kill();
    };
  }, [isMobile]);

  return (
    <div ref={scrollContainerRef}>
      <Scene
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 10,
        }}
      >
        <ambientLight intensity={2} />
        <fog attach="fog" args={["#17082F", 2, 100]} />
        <SceneContent gravity={gravity} isMobile={isMobile} />
        <CustomPlane position={[0, -10, 0]} scale={150} color="#AF2EFF" />
      </Scene>
    </div>
  );
};
