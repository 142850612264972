const CustomPlane = ({
  scale = 100,
  position = [0, 0, 0],
  rotation = [-90, 0, 0],
  color = "#FFFFFF",
}) => {
  return (
    <mesh
      receiveShadow
      position={[position[0], position[1], position[2]]}
      rotation={[
        (rotation[0] * Math.PI) / 180,
        (rotation[1] * Math.PI) / 180,
        (rotation[2] * Math.PI) / 180,
      ]}
    >
      <planeGeometry args={[scale, scale]} />
      <meshStandardMaterial attach="material" color={color} />
    </mesh>
  );
};

export default CustomPlane;
