import { useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import BurguerClass from "@/components/layout/Header/Burger/Burger.module.scss";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

interface UseGsapBurgerTimelineProps {
  triggerClass: string;
  endAdjustment?: number;
  startAdjustment?: number;
}

export const useGsapBurgerTimeline = ({
  triggerClass,
  endAdjustment,
  startAdjustment,
}: UseGsapBurgerTimelineProps) => {
  const timelineRef = useRef<gsap.core.Timeline | null>(null);

  useGSAP(
    () => {
      let end = "bottom top";
      let start = "top top";

      if (endAdjustment) end = getAdjustedMarkers(end, endAdjustment);
      if (startAdjustment) start = getAdjustedMarkers(start, startAdjustment);

      const burguerTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.${triggerClass}`,
          start: start,
          end: end,
          toggleActions: "play reverse play reverse",
          onEnter: (scrolltrigger) => {
            scrolltrigger.refresh();
          },
        },
      });

      burguerTimeline.to(`.${BurguerClass.burger}`, {
        "--BurguerBackground": "#fff",
        duration: 0.15,
      });

      timelineRef.current = burguerTimeline;

      return () => {
        if (!timelineRef.current) return;

        timelineRef.current.scrollTrigger?.kill();
      };
    },
    { dependencies: [triggerClass, BurguerClass.burger] }
  );

  return timelineRef;
};

function getAdjustedMarkers(baseMarkers, adjustment) {
  if (adjustment === undefined || adjustment === 0) return baseMarkers;

  const operator = adjustment < 0 ? "-=" : "+=";
  const [marker, point] = baseMarkers.split(" ");

  return `${marker}${operator}${Math.abs(adjustment)} ${point}`;
}
