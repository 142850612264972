import React from "react";
import { FooterContactDetails } from "./FooterContactDetails/FooterContactDetails";
import { FooterCopyrightLinks } from "./FooterCopyrightLinks/FooterCopyrightLinks";
import style from "./FooterContact.module.scss";

export const FooterContact = () => {
  return (
    <section className={` container-header`}>
      <div className={`${style.sectionContainer}`}>
        <FooterContactDetails />
        <FooterCopyrightLinks />
      </div>
    </section>
  );
};
