"use client";
import ChipScroll from "@/components/common/Buttons/ChipScroll/ChipScroll";
import { Ballpit } from "../Ballpit";
import style from "./FooterAnimation.module.scss";
import { MagneticChipButton } from "@/components/common/Buttons/MagneticChipButton/MagneticChipButton";
import { useEffect, useRef } from "react";
import { useRefStore } from "../../../../store/ref-store";
import { useTranslations } from "next-intl";
import { useContact } from "@/provider/ContextForm";

export const FooterAnimation = () => {
  const translate = useTranslations("footerAnimation");
  const prefooter = useRef(null);

  const { setIsContactOpen } = useContact();

  useEffect(() => {
    if (prefooter.current) {
      useRefStore.setState({ prefooter: prefooter.current });
    }
  }, []);

  return (
    <>
      <section className={`${style.mainFooter}`} ref={prefooter}>
        <div className="prefooter">
          <div className={`${style.titleContainer} scaleText container`}>
            <p>{translate("subtext")}</p>
            <div className={style.heroTitle}>
              <h2 className="title-section">
                <span>{translate("title_first_words")} </span>
                <span>{translate("title_second_word")} </span>
                <span>{translate("title_third_word")}</span>
              </h2>
            </div>
          </div>
          <div className={style.buttonContainer}>
            <MagneticChipButton
              label={translate("call_to_action_button")}
              variant="base"
              onClick={() => setIsContactOpen(true)}
            />
          </div>
          <ChipScroll />
          <Ballpit />
        </div>
      </section>
    </>
  );
};
