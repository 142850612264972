"use client";

import React, { useEffect, useRef } from "react";
import style from "./Footer.module.scss";
import { FooterAnimation } from "./FooterAnimation/FooterAnimation";
import { FooterContact } from "./FooterContact/FooterContact";
import gsap from "gsap";
import { useRefStore } from "../../../store/ref-store";
import { useDeviceDetector } from "@/utils/useDeviceDetector";
import { useGsapBurgerTimeline } from "@/utils/useGsapBurgerTimeline";

export const Footer = () => {
  const footerTwoRef = useRef(null);
  const timelineRef = useRef(null);
  const { isMobile } = useDeviceDetector();

  useEffect(() => {
    if (footerTwoRef.current !== null) {
      useRefStore.setState({ footerTwo: footerTwoRef.current });
    }
  }, []);

  useEffect(() => {
    if (timelineRef.current !== null) {
      timelineRef.current.pause(0);
      timelineRef.current.scrollTrigger?.kill();
      timelineRef.current.kill();
      timelineRef.current = null;
    }

    if (!isMobile) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".footerOne",
          start: "top top",
          pin: true,
          pinSpacing: false,
          scrub: 1,
        },
        ease: "power4",
      });
      tl.to(".footerOne .scaleText", {
        scale: 0.9,
        y: "-=75",
      });
      tl.to(
        ".footerTwo",
        {
          y: 0,
        },
        "<="
      );

      timelineRef.current = tl;
    }

    return () => {
      if (timelineRef.current !== null) {
        timelineRef.current.pause(0);
        timelineRef.current.scrollTrigger?.kill();
        timelineRef.current.kill();
        timelineRef.current = null;
      }
    };
  }, [isMobile]);

  useGsapBurgerTimeline({
    triggerClass: style.footerOne,
  });

  return (
    <footer className={`${style.footerContainer}`}>
      <div className={`${style.footerOne} footerOne`}>
        <FooterAnimation />
      </div>
      <div className={`${style.footerTwo} footerTwo`} ref={footerTwoRef}>
        <FooterContact />
      </div>
    </footer>
  );
};
